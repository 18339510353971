import { Pipe, PipeTransform } from '@angular/core';
import { format, parseISO } from 'date-fns';
import { es } from 'date-fns/locale';

@Pipe({
  name: 'formatShortDate'
})
export class FormatShortDatePipe implements PipeTransform {
  constructor() { }

  transform(date: any): string {
    return date ? format(parseISO(date), "dd 'de' MMMM", {
      locale: es
    }) : '';
  }
}
