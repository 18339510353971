import { Fee } from "../generic/fee.model";
import { Ticket } from '../ticket';
import { ClientStock } from "./client-stock.model";
import { Event } from "./event.model";
import { Stock } from './stock.model';
import { VariationAddOn } from "./variation-addon.model";

export class TicketVariation {
  id: number;
  name: string;
  description: string;
  price: number;
  pax: number;
  default: boolean;
  color: string;
  event?: Event;
  fee: Fee;
  tickets?: Ticket[];
  stock?: Stock;
  clientStock: ClientStock;
  variationAddons: VariationAddOn[];
  private: boolean;
  order?: number;

  get priceWithTaxes(): number {
    return this.fee.calculate(this.price);
  }

  static create(variation: Partial<TicketVariation>): TicketVariation {
    const model = new TicketVariation();

    model.id = variation.id;
    model.name = variation.name;
    model.price = variation.price;
    model.pax = variation.pax;
    model.default = variation.default;
    model.stock = variation.stock;
    if (variation.fee) {
      model.fee = Fee.create(variation.fee);
    }

    if (variation.event) {
      model.event = variation.event;
    }

    return model;
  }
}
