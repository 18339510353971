import { TicketVariation } from '../models';
import { FeeDTO } from './fee.dto';
import { OrderItemDTO } from './order-item.dto';
import { TicketDTO } from './ticket.dto';
import { StockDTO } from './stock.dto';
import { EventDTO } from './event.dto';
import { ClientStock } from '../models/event/client-stock.model';
import { VariationAddOnDTO } from './variation-addon.dto';

export class TicketVariationDTO {
  id: number;
  name: string;
  description: string;
  price: number;
  color: string;
  pax: number;
  stock?: StockDTO;
  default: boolean;
  fee: FeeDTO;
  event?: EventDTO;
  tickets?: TicketDTO[];
  variation_add_ons: VariationAddOnDTO[];
  private: boolean;
  order?: number;

  clientStock: ClientStock;

  static forClient(entity: TicketVariationDTO): TicketVariation {
    const model = new TicketVariation();

    model.id = entity.id;
    model.name = entity.name;
    model.description = entity.description;
    model.price = entity.price;
    model.pax = entity.pax;
    model.default = entity.default;
    model.color = entity?.color?.replace('color', '#') ?? 'white';
    model.order = entity.order;

    model.clientStock = entity.clientStock;
    model.private = entity.private ?? false;

    if (entity.stock) {
      model.stock = StockDTO.forClient(entity.stock);
    }

    if (entity.fee) {
      model.fee = FeeDTO.forClient(entity.fee);
    }

    if (entity.event) {
      model.event = EventDTO.forClient(entity.event);
    }

    if (entity.tickets) {
      model.tickets = entity.tickets.map((tk) => TicketDTO.forClient(tk));
    }

    if (entity.variation_add_ons) {
      model.variationAddons = entity.variation_add_ons.map((vao) => VariationAddOnDTO.forClient(vao));
    }

    return model;
  }
}
